import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';

import Bowser from 'bowser';
import { QrCode } from 'react-qrcode-pretty';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Link from '@mui/material/Link';
import MapIcon from '@mui/icons-material/Map';
import Typography from '@mui/material/Typography';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';

import CenteredBox from '../components/ui/CenteredBox.tsx';
import Layout from '../components/ui/Layout.tsx';
import NoContentAlert from '../components/ui/NoContentAlert.tsx';
import SuccessAlert from '../components/ui/SuccessAlert.tsx';

import api from '../utils/api.ts';
import AuthContext from '../context/AuthContext';

import { DOWNLOAD_URL } from '../consts/app';
import {
  APP_NAME,
  APP_URL,
} from '../config';
import {
  backdropBlur,
  brightOrange,
  DRAWER_WIDTH,
  equalBoxShadow,
  fabBoxShadow,
  WIDTH_BREAKPOINT,
} from '../theme';

const StyledAlert = styled(Alert)`
  backdrop-filter: ${backdropBlur};
  background-color: rgba(0, 0, 0, 0.6);
  background-image: unset;
`;

const StyledBox = styled(Box)`
  canvas {
    border-radius: 5px;
    box-shadow: ${equalBoxShadow};
  }
`;

const StyledImage = styled('img')`
  width: 100%;
  // height: 100%;
  object-fit: contain;
  // max-width: 100%;
  // max-height: 100%;
  // height: inherit !important;
`;

const TextButton = styled(Button)`
  color: #fff;
  // position: absolute;
  // bottom: 10%;
  // width: 100%;
`;

export default function Download() {
  const { t } = useTranslation();

  const { user } = useContext(AuthContext);

  const width = useSelector((state) => state.app.width);

  const standaloneDisplayMode = window.matchMedia('(display-mode: standalone)').matches;
  const language = useSelector(state => state.settings.language);

  const parsedUserAgent = Bowser.parse(window.navigator.userAgent);
  const browser = parsedUserAgent.browser;
  const os = parsedUserAgent.os;
  const platform = parsedUserAgent.platform;
  const engine = parsedUserAgent.engine;

  const [deferredPrompt, setDeferredPrompt] = useState();
  const [loading, setLoading] = useState(true);
  const [seconds, setSeconds] = useState(3);

  const [snackbarOpen, setSnackbarOpen] = useState(true);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then(async (choiceResult) => {
        setDeferredPrompt(null);
      });
    };
  };

  const updateLastVisit = async () => {
    await api.patch('/counters/download-page-visit/');
  };

  const mobileDownloadInfo = () => (
    <React.Fragment>
      <CenteredBox m={4}>
        <Stack>
          <Typography align="center">
            {t('We highly encourage you to install our app on your mobile device.')}
          </Typography>
          <Typography align="center">
            {t('Just visit')} <Link href={DOWNLOAD_URL}>{DOWNLOAD_URL}</Link> {t('or scan the QR code below')}.
          </Typography>
        </Stack>
      </CenteredBox>
      <CenteredBox>
        <StyledBox m={4}>
          <QrCode
            value={`${APP_URL}/download`}
            // variant="fluid"
            variant={{
              eyes: 'fluid',
              body: 'fluid',
            }}
            size={250}
            color={{
              eyes: 'rgba(255, 255, 255, 0.75)',
              body: 'rgba(255, 255, 255, 0.75)',
            }}
            margin={10}
            bgColor="#1A1A1A"
            bgRounded
          // divider
          />
        </StyledBox>
      </CenteredBox>
    </React.Fragment>
  );

  const suggestChrome = () => (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackbarOpen}
    >
      <StyledAlert
        severity="info"
        onClose={() => setSnackbarOpen(false)}
      >
        {t('We encourage you to install our application via Google Chrome browser.')}
      </StyledAlert>
    </Snackbar>
  );

  const chromiumDownloadInfo = () => (
    <Box sx={{
      height: width <= WIDTH_BREAKPOINT ? '100vh' : '92vh',
      overflow: 'hidden',
    }}>
      {platform.type === 'mobile' && (
        <StyledImage src={`/download-info-chromium-android-${language}.png`} />
      )}
      <Stack>
        <CenteredBox sx={{
          position: platform.type === 'mobile' ? 'fixed' : undefined,
          // right: '1.5vh',
          top: '40vh',
          width: '100%',
        }}>
          {JSON.parse(localStorage.getItem('appInstalled')) ? (
            <Stack>
              <SuccessAlert text={t('You already have our app installed.')} />
              {platform.type === 'mobile' && (
                <Box mt={4}>
                  <Link href={APP_URL} target="_blank"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      textDecoration: 'none',
                    }}>
                    <TextButton variant="text" onClick={handleInstallClick}>
                      <Typography variant="h5">
                        {t('Launch')}
                      </Typography>
                    </TextButton>
                  </Link>
                </Box>
              )}
            </Stack>
          ) : (
            <Stack alignItems="center" pt={1}>
              <Fab
                onClick={handleInstallClick}
                variant="contained"
                // endIcon={platform.type === 'desktop' ? <GetAppIcon /> : <AddToHomeScreenIcon />}
                // size="70"
                sx={{
                  width: '100px',
                  height: '100px',
                  zIndex: 10,
                  backgroundColor: 'transparent',
                  fontSize: '2rem',
                  boxShadow: fabBoxShadow,
                  // pointerEvents: isMapDragged ? 'none' : 'auto',
                  '&:hover': {
                    backgroundColor: isMobile ? 'transparent' : 'rgba(255, 255, 255, 0.08)',
                  },
                  color: '#fff',
                  // position: platform.type === 'mobile' ? 'fixed' : undefined,
                  // right: '1.5vh',
                  // top: '40vh',
                  // width: '100%',
                }}
              >
                <SaveAltIcon sx={{ fontSize: '50px' }} />
              </Fab>
              <Box mt={5}>
                <TextButton variant="text" onClick={handleInstallClick}>
                  <Typography variant="h5">
                    {t('Download {{appName}}', { appName: APP_NAME })}
                  </Typography>
                </TextButton>
              </Box>
            </Stack>
          )}
        </CenteredBox>
        {platform.type === 'desktop' && mobileDownloadInfo()}
      </Stack>
    </Box>
  );

  const appleNotSupported = () => (
    <>
      <NoContentAlert
        text={t('Installation not supported using {{browserName}} on Apple devices.', { browserName: browser.name })}
      />
      <Typography>
        {t('Please visit')} <Link href={DOWNLOAD_URL}>{DOWNLOAD_URL}</Link> {t('using other browser.')}
      </Typography>
    </>
  );

  const downloadInfo = () => {
    if (browser.name === 'Chrome') {
      return os.name !== 'iOS' ? (
        chromiumDownloadInfo()
      ) : (
        <StyledImage src={`/download-info-chrome-ios-${language}.png`} />
      );
    } else if (browser.name === 'Microsoft Edge') {
      return os.name !== 'iOS' ? (
        <>
          {chromiumDownloadInfo()}
          {suggestChrome()}
        </>
      ) : (
        appleNotSupported()
      );
    } else if (browser.name === 'Safari') {
      return platform.type === 'mobile' ? (
        <StyledImage src={`/download-info-safari-ios-${language}.png`} />
      ) : (
        <>
          {width <= WIDTH_BREAKPOINT * 1.5 ? (
            <StyledImage src={`/download-info-safari-ios-${language}.png`} />
          ) : (
            mobileDownloadInfo()
          )}
        </>
      );
    } else if (browser.name === 'Firefox' && platform.type === 'mobile') {
      return os.name !== 'iOS' ? (
        <>
          <StyledImage src={`/download-info-firefox-android-${language}.png`} />
          {suggestChrome()}
        </>
      ) : (
        <StyledImage src={`/download-info-firefox-ios-${language}.png`} />
      );
    } else if (browser.name === 'Opera' && platform.type === 'mobile') {
      return os.name !== 'iOS' ? (
        <>
          <StyledImage src={`/download-info-opera-android-${language}.png`} />
          {suggestChrome()}
        </>
      ) : (
        appleNotSupported()
      );
    } else if (['Firefox', 'Opera'].includes(browser.name) && platform.type !== 'mobile') {
      return (
        <Box sx={{ height: '92vh' }}>
          <CenteredBox>
            <Stack>
              <NoContentAlert
                text={t('Installation not supported using {{browserName}} on desktop device.', { browserName: browser.name })}
                color={brightOrange}
              />
              <Typography textAlign="center">
                {t('Use another browser.')}
              </Typography>
            </Stack>
          </CenteredBox>
          {mobileDownloadInfo()}
        </Box>
      );
    } else if (browser.name === 'Samsung Internet for Android') {
      return (
        <>
          <StyledImage src={`/download-info-samsung-android-${language}.png`} />
          {suggestChrome()}
        </>
      );
    } else {
      return (
        <>
          <NoContentAlert
            text={t('Installation with {{browserName}} not supported.', { browserName: browser.name })}
            color={brightOrange} />
          {platform.type === 'mobile' ? suggestChrome() : mobileDownloadInfo()}
        </>
      );
    };;
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
      setLoading(false);
      localStorage.setItem('appInstalled', false);
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('downloadPageVisited', true);
    if (user) updateLastVisit();
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (standaloneDisplayMode) {
      const timer = seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
      if (seconds === 0) {
        clearInterval(timer);
        window.location.href = '/';
      };
      return () => clearInterval(timer);
    };
  }, [seconds]);

  // maybe TODO
  /* useEffect(() => {
    if (user) updateLastVisit();
  }, [user]); */

  // useEffect(() => {
  //   console.log('deferredPrompt', deferredPrompt);
  // }, [deferredPrompt]);

  const debugInfo = () => {
    return (
      <React.Fragment>
        <Typography>
          browser: {JSON.stringify(browser, null, 2)}
        </Typography>
        <Divider />
        <Typography>
          os: {JSON.stringify(os, null, 2)}
        </Typography>
        <Divider />
        <Typography>
          platform: {JSON.stringify(platform, null, 2)}
        </Typography>
        <Divider />
        <Typography>
          engine: {JSON.stringify(engine, null, 2)}
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Box sx={{
          display: 'flex',
          // flexGrow: 1,
          paddingLeft: width > WIDTH_BREAKPOINT && `${DRAWER_WIDTH + 16}px`,
          // height: '100vh',
        }}>
          {standaloneDisplayMode ? (
            <Box sx={{
              paddingTop: '16vh',
              width: '100%',
              height: width <= WIDTH_BREAKPOINT ? '100vh' : '92vh',
            }}>
              <Stack>
                <SuccessAlert text={t('The app is running!')} />
                <Typography textAlign="center" mt={4}>
                  {t('Redirecting to map in')} {seconds}s
                </Typography>
                <CenteredBox mt={4}>
                  <Button
                    href="/"
                    variant="contained"
                    startIcon={<MapIcon />}
                    disabled={loading}
                  >
                    {t('Go to map now')}
                  </Button>
                </CenteredBox>
              </Stack>
            </Box>
          ) : (
            <Stack sx={{ width: '100%' }}>
              {downloadInfo()}
              {/* {debugInfo()} */}
            </Stack>
          )}
        </Box>
      </Layout>
    </React.Fragment>
  );
}